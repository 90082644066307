<template>
  <div class="row">
    <div class="col-md-12">
      <label class="sub-title pb-2"
        >Base Node<span class="base">●</span>
      </label>
    </div>
    <div class="col-md-12">
      <div class="form-check form-check-inline">
        <input
          type="radio"
          name="baseNodeRadioOptions"
          class="form-check-input"
          v-model="findNearest"
          value="true"
          id="findNearest"
          @click="inputDisable(true)"
        /><label class="form-check-label" for="findNearest">Find Nearest</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          type="radio"
          name="baseNodeRadioOptions"
          class="form-check-input"
          v-model="findNearest"
          value="false"
          id="useSpecific"
          @click="inputDisable(false)"
        />
        <label class="form-check-label" for="useSpecific">Use Specific</label>
      </div>
      <div class="form-group">
        <label for="serial_number">Serial Number</label>
        <input
          type="text"
          name="serial_number"
          class="form-control"
          v-model="serial"
          :disabled="disabled"
          :class="{
            'border-danger': !checkSerialNumber(serial),
          }"
        />
      </div>
      <div class="form-group">
        <label for="location">Location</label>
        <input
          type="text"
          name="location"
          class="form-control"
          v-model="bn_location"
          :class="{
            'border-danger': !checkCoordinates(bn_location),
          }"
          :disabled="disabled"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="azimuth">Azimuth</label>
        <input
          type="text"
          name="azimuth"
          class="form-control"
          v-model="azimuth"
          :class="{ 'border-danger': !checkNumber(azimuth) }"
          :disabled="disabled"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="downtilt">Tilt</label>
        <input
          type="text"
          name="downtilt"
          class="form-control"
          v-model="downtilt"
          :class="{ 'border-danger': !checkNumber(downtilt) }"
          :disabled="disabled"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="height">Height AGL (m)</label>
        <input
          type="text"
          name="height"
          class="form-control"
          v-model="height_agl"
          :class="{ 'border-danger': !checkNumber(height_agl) }"
          :disabled="disabled"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="height">Height GPS (m)</label>
        <input
          type="text"
          name="height"
          class="form-control"
          v-model="height_gps"
          :class="{ 'border-danger': !checkNumber(height_gps) }"
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    operatorKey: String,
    operator: String,
  },
  data() {
    return {
      serial: "",
      cell: "",
      market: "",
      site: "",
      sector: "",
      firmware: "",
      region: "",
      bn_location: "",
      azimuth: "",
      height_agl: "",
      height_gps: "",
      ground_msl: "",
      downtilt: "",
      carrier: [],
      findNearest: true,
      submitted: false,
      disabled: true,
    };
  },
  computed: {
    useSpecific() {
      return !this.findNearest;
    },
  },
  methods: {
    inputDisable(val) {
      this.disabled = val;
      this.submitted = false;
      //this.clearInputs();
    },
    clearInputs() {
      this.serial = "";
      this.cell = "";
      this.market = "";
      this.site = "";
      this.sector = "";
      this.firmware = "";
      this.region = "";
      this.bn_location = "";
      this.azimuth = "";
      this.height_agl = "";
      this.height_gps = "";
      this.downtilt = "";
      this.ground_msl = "";
      this.carrier = [];

      // Update findNearest and useSpecific, then call inputDisable
      this.findNearest = true;
      this.inputDisable(true);
    },
    checkSerialNumber(value) {
      if (value == "" && !this.disabled && this.submitted) {
        return false;
      }
      return true;
    },
    checkNumber(value) {
      if (value == "" && !this.disabled && this.submitted) {
        return false;
      }
      return this.$utils.isValidNumber(value);
    },
    checkCoordinates(value, type = "") {
      if (value == "" && !this.disabled && this.submitted) {
        return false;
      }
      return this.$utils.isValidCoordinates(value, type);
    },
    isFormValid() {
      this.submitted = true;
      return (
        this.checkSerialNumber(this.serial) &&
        this.checkNumber(this.azimuth) &&
        this.checkNumber(this.height_agl) &&
        this.checkNumber(this.height_gps) &&
        this.checkNumber(this.downtilt) &&
        this.checkCoordinates(this.bn_location)
      );
    },
    getJsonData() {
      let bnObject = {};
      if (!this.disabled) {
        bnObject.operator = this.operator;
        if (this.serial != "") bnObject.serial = this.serial;
        if (this.cell != "") bnObject.cell = this.cell;
        if (this.site != "") bnObject.site = this.site;
        if (this.sector != "") bnObject.sector = this.sector;
        if (this.market != "") bnObject.market = this.market;
        if (this.region != "") bnObject.region = this.region;
        if (this.firmware != "") bnObject.firmware = this.firmware;
        if (this.ground_msl != "") bnObject.ground_msl = this.ground_msl;
        if (this.bn_location != "") {
          let bnLocationArray = this.bn_location.split(", ");
          bnObject.latitude = parseFloat(bnLocationArray[0]);
          bnObject.longitude = parseFloat(bnLocationArray[1]);
        }
        if (this.height_agl != "") bnObject.height_agl = this.height_agl;
        if (this.height_gps != "") bnObject.height_gps = this.height_gps;
        if (this.azimuth != "") bnObject.azimuth = this.azimuth;
        if (this.downtilt != "") bnObject.downtilt = this.downtilt;
        if (this.carrier.length > 0) bnObject.carrier = this.carrier;
      }
      return bnObject;
    },
    updateFields(data) {
      this.findNearest = false;
      this.inputDisable(false);
      this.serial = data.serial;
      this.cell = data.cell;
      this.market = data.market;
      this.site = data.site;
      this.sector = data.sector;
      this.firmware = data.firmware;
      this.region = data.region;
      this.bn_latitude = data.latitude ? parseFloat(data.latitude) : "";
      this.bn_longitude = data.longitude ? parseFloat(data.longitude) : "";
      if (this.bn_latitude != "" && this.bn_longitude != "") {
        this.bn_location = this.bn_latitude + ", " + this.bn_longitude;
      }

      this.height_agl = data.height_agl ? parseFloat(data.height_agl) : "";
      this.height_gps = data.height_gps ? parseFloat(data.height_gps) : "";
      this.azimuth = data.azimuth ? parseFloat(data.azimuth) : "";
      this.downtilt = data.downtilt ? parseFloat(data.downtilt) : "";
      this.ground_msl = data.ground_msl ? parseFloat(data.ground_msl) : "";
      this.carrier = data.carrier;
    },
  },
};
</script>
